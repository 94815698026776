import React from "react";

export default function Product_down(){
    return (
        <section className="w-sm-100 pt-1" id="manager">
        <div class="row border-top border-top-secondary pt-2"></div>    
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
            <div className="d-flex align-items-center mb-3"><strong className="me-2" style={{width:'130px'}}>대  상  자 </strong>
                <p className="fw-medium mb-0 text-secondary me-sm-6 mb-min"><ul>만 19세이상 직장인 및 직장인 성격의 프리랜서, 주부<br />단, NICE신용평점 350점 이상인 자</ul></p>
              </div>
              
              <p className="mb-3 border-top"></p>
              <div className="d-flex align-items-center mb-3"> <strong className="me-2" style={{width:'130px'}}>대  출  한  도 </strong>
                <p className="fw-medium mb-0 text-secondary me-sm-6 mb-min"><ul>최소 100만원~최대1억원</ul></p>
              </div>
              <p className="mb-3 border-top"></p>
              <div className="d-flex align-items-center mb-3"><strong className="me-2 col-red" style={{width:'130px'}}>대  출  금  리 </strong>
                <p className="fw-medium mb-0 text-secondary me-sm-6 mb-min"><ul className="col-red">최저 10.9%~19.9%(고정금리)</ul> </p>
              </div>
              
              <p className="mb-3 border-top"></p>
              <div className="d-flex align-items-center mb-3"><strong className="me-2" style={{width:'130px'}}>이자부과시기 </strong>
                <p className="fw-medium mb-0 text-secondary me-sm-6 mb-min"><ul> 매월 고객대출 약정일</ul></p>
              </div>
              
              <p className="mb-3 border-top"></p>
              <div className="d-flex align-items-center mb-3"><strong className="me-2  col-red" style={{width:'130px'}}>연  체  금  리 </strong>
                <p className="fw-medium mb-0 text-secondary me-sm-6 mb-min"> <ul className="col-red">연체시:약정이자+연3%(*연체이자율 상한:연20%이내)</ul></p>
              </div>
              <p className="mb-3 border-top"></p>
              <div className="d-flex align-items-center mb-3"><strong className="me-2" style={{width:'130px'}}>대  출  기  간 </strong>
                <p className="fw-medium mb-0 text-secondary me-sm-6 mb-min"><ul> 12개월~120개월(대출금액별 대출기간 차등)</ul></p>
              </div>
              <p className="mb-3 border-top"></p>
              <div className="d-flex align-items-center mb-3"><strong className="me-2" style={{width:'130px'}}>대출기한전<br/>상환수수료 </strong>
                <p className="fw-medium mb-0 text-secondary me-sm-6 mb-min" ><ul> 대출취급 후 3년이내 상환금액의 2.0% 이후는 면제<br />(기한전상환대출금액x기한전상환수수료x대출잔여일수/대출기간)</ul></p>
              </div>
              <p className="mb-3 border-top"></p>
              <div className="d-flex align-items-center mb-3"><strong className="me-2  col-red" style={{width:'130px'}}>인지비용부담 </strong>
                <p className="fw-medium mb-0 text-secondary me-sm-6 mb-min"> <ul className="col-red">대출금 5천만원까지는 수입인지 비용이 없으며, 5천만원 초과시 대출금액별 수입인지 비용차등 적용<br />수입인지 비용은 각50%씩 고객과 저축은행이 부담</ul></p>
              </div>
              <p className="mb-3 border-top"></p>
              <div className="d-flex align-items-center mb-3"><strong className="me-2" style={{width:'130px'}}>상  환  방  식 </strong>
                <p className="fw-medium mb-0 text-secondary me-sm-6 mb-min"><ul> 원리금균등상환방법(최대 120개월)</ul></p>
              </div>
              <p className="mb-3 border-top"></p>
              <div className="d-flex align-items-center mb-3"><strong className="me-2 col-red" style={{width:'130px'}}>유  의  사  항 </strong>
                <p className="fw-medium mb-0 text-secondary me-sm-6 mb-min"> 
                <ul className="col-red">저축은행 심사기준과 고객 신용도에 따라 대출여부가 결정됩니다.<br />
                대출금의 상환 또는 이자납입이 지연될 경우 연체이율이 적용되며 예금 등 기타채권관의 상계나 법적절차 등으로 재산상의 불이익을 당할 수 있습니다.<br />
                계약 체결 전 상품설명서 및 약관을 확인하시기 바랍니다.<br />
                해당 상품에 대해 충분한 사전 설명을 들을 권리가 있으며, 설명을 이해한 후 거래하시기 바랍니다.<br />
                대출상품 이용시 귀하의 신용등급 또는 개인신용평점이 하락할 수 있습니다.<br />
                신용등급 또는 개인신용평점이 하락하면 추가 대출이 제한되거나, 대출금리 상승, 대출한도 감소 등 불이익이 발생할 수 있습니다.<br />
                연체 시 계약기한 만료전 원리금 면제의무가 발생할 수 있습니다.<br />
                대출모집법인 및 소속상담사는 고객에게 별도의 수수료를 요구하거나 수취하지 않습니다. 중개수수료를 요구하거나 받는 것은 불법입니다.<br />
                금융소비자는 소득, 재산증가, 신용도 상승, 재무상태개선, 기타 등으로 본인의 신용상태가 개선되었다고 판단되는 경우 저축은행에 자신이 적용받는 금리인하를 <br />요구할 수 있는 권리가 있습니다. 다만, 관련절차 및 내규에 따라 금리인하가 되지 않을 수 있습니다. <br />
                금융소비자에 관한 법률에 따른 금융소비자의 권리(청약철회권, 위법계약해지권, 자료열람요구권)을 보장하고 있습니다.<br />
                자세한 내용은 한국투자저축은행 홈페이지 고객센터 → 금융소비자보호포털에서 확인하시기 바랍니다.<br />
                기타 자세한 사항은 대표 전화번호(1644-4517)로 문의하여 주시기 바랍니다. <br />
                금융소비자는 방문판매 목적으로 방문 또는 전화 연락하는 것을 금지하도록 연락금지요구권을 행사할 수 있습니다.<br />
                □ 행사방법 : (주)씨에이치파트너스 대표번호 070-4144-7355
                </ul></p>
              </div>
            </div>
          </div>
        </div>

      </section>
    );

}
