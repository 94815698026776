import React from "react";
import { Link } from "react-router-dom";

export default function Buttom(){
  const newPosition = `background-position: top !important`;
    return (
        <section class="py-md-11 py-8" id="superhero">

        <div class="bg-holder z-index--1 bottom-0 d-none d-lg-block background-position-top " style={{backgroundImage:'url("assets/img/superhero/oval.png")',opacity:'.5', newPosition}} >
        </div>
       

        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-9 text-center">
            </div>
          </div>
        </div>

      </section>
    );
}