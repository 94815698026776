import React from "react";

export default function product_agree(){
    return (
        <section className="pt-5" id="manager">

        <div className="container">
          <div className="row">
            <div className="col-lg-11">      
              <div className="mb-6 text-secondary outer-container"  style={{ border: '1px solid #f5f5f5', backgroundColor: '#f5f5f5', padding: '10px', textAlign: 'center', }}>
                <h2 style={{ fontSize: '24px' }}>개인정보 수집, 이용 동의서</h2><br/>
                <p>
                  한국투자저축은행 대출모집법인 (주)씨에이치파트너스(상담사)(이하”회사”)와 고객님과 대출 상담을 위해 고객님의 개인정보를 수집, 이용하거나 제 3자에게 제공하고자하는 경우에는 [개인정보 보호법] 제 15조 1항 제1호, 제17조 제1항 제1호,제24조 제1항 제1호에 따라 고객님의 동의를 얻어야 합니다. 이에 회사가 아래의 내용과 같이 고객님의 개인정보를 수집 이용 또는 제공하는 것에 동의합니다.
                </p>
                <table className="tblContent">
                  <tbody>
                  <div style={{ textAlign: 'left', backgroundColor: '#ffffff' }}>
                    <tr>
                      <td className="line1" style={{backgroundColor: '#ffffff'}}>수집, 이용 목적</td>
                      <td >
                      <div style={{ textAlign: 'left' }}>
                        ▣ 대출상담 및 해당 금융사에 시스템(Admin)을 통해 대출 신청 <br />
                        ▣ 상품 및 서비스 홍보<br />
                        ▣ 로그분석 및 마케팅 통계에 활용 <br />
                      </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="line1" style={{backgroundColor: '#ffffff'}}>수집, 이용할 항목</td>
                      <td>
                      <div style={{ textAlign: 'left' }}>
                        ▣ 필수개인정보항목:<br />
                        - 이름/ 생년월일(남,여),통신사, 휴대폰번호<br />
                        ▣ 선택수집항목 : <br />
                        - 부동산 정보, 소득정보, 대출필요금액, 보유대출금액, 대출상담에 목적에관한정보<br />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="line1" style={{backgroundColor: '#ffffff'}}>수집 방법</td>
                      <td >
                      <div style={{ textAlign: 'left' }}>
                        ▣ 홈페이지 온라인 상담신청 및 전화상담 신청
                      </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="line1" style={{backgroundColor: '#ffffff'}}>제공받는자</td>
                      <td >
                      <div style={{ textAlign: 'left' }}>
                        한국투자저축은행
                      </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="line1" style={{backgroundColor: '#ffffff'}}>보유, 이용기간</td>
                      <td >
                      <div style={{ textAlign: 'left' }}>
                        원칙적으로, 개인정보 수집 및 이용목적이 달성한 후에는 해당 정보를 지체 없이 파기합니다. 다만, 상법,전자상거래 등에서의 소비자보호에 관한 법률 등 관련 법령의 규정에 의하여 보존할 필요성이 있는 경우에는 아래와 같이 고객의 개인정보를 보관할 수 있습니다. 이 경우 개인정보는 보관의 목적으로만 관리됩니다. <br />
                        보존항목 : 이름,휴대폰번호,<br />
                        보존근거 : 서비스 이용의 혼선 방진, 불법적 사용자에 대한 관련 기관 수사협조<br />
                        보존기간 : 1개월 이후 즉시 파기
                      </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="line1" style={{backgroundColor: '#ffffff'}}>동의를 거부할 권리 및 동의를 거부할 경우의 불이익</td>
                      <td>
                      <div style={{ textAlign: 'left' }}>
                        위 개인정보의 수집.이용에 대한 동의를 거부할 수 있으며, 동의 후에도 언제든지 철회 가능합니다. 동의하지 않은 수집 이용 목적과 관련된 상품 및 서비스 안내 등의 편의는 제공받을 수 없습니다.
                      </div>
                      </td>
                    
                    </tr>
                    </div>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </section>
    );

}
