import React, { useEffect, useRef, useState, useCallback } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import LoginPage from "../pages/login";
import axios from 'axios';
import MozipInfoPage from "../pages/mozipinfo";
import CounselorListPage from '../pages/product_list';
import { useAuthContext } from "./context/AuthContext";
import { useProductContext } from "./context/ProductContext";

//import { Link } from 'react-router-dom';
//import { FiShoppingBag } from 'react-icons/fi';
//yimport { BsFilePencilFill } from 'react-icons/bs';


export default function Navbar() {
    const [options, setOptions] = useState([]);
    const [classSticky, setSticky] = useState(false);
    const [open, setOpen] = useState(false);
    const [open_mozip, setOpen_Mozip] = useState(false);
    const [openCounselorList, setOpenCounselorList] = useState(false);
    const [mode, setMode] = useState();
    const [clientIP, setClientIP] = useState(null);
    const [allowedIPs, setAllowedIPs] = useState([]);
    //const {UserId_nm} = useAuthContext();
    const navigate = useNavigate();
    const isLogin = sessionStorage.getItem('isLogin');
    const UserId_nm = sessionStorage.getItem('nickname');

    
    /*
    useEffect(() => {
        axios.defaults.withCredentials =true;
        const sessionMode = sessionStorage.getItem('modeu');
        const parseValue = JSON.parse(sessionMode);

        var params_info = ""

        if(parseValue === "" || parseValue === null) {
            params_info = ""
        }
        else {
            params_info = parseValue['modechk']
        }
    
        axios.get("/authcheck", { 
            params: {
                params_info: params_info
            },
            headers: {
              "Content-Type": "application/json",
            },
        })
          .then((response) => {
            const json = response.data;
            if (json.isLogin === "True") {
                setMode("WELCOME");
              } else {
                setMode("LOGIN");
              }
            });
      }, []);
*/

useEffect(() => {
  // Fetch client's IP address
  axios.get("/client", {
    headers: {
      'Cache-Control': 'no-cache',
    }
  })
  .then(response => {
    const clientIP = response.data.clientIP;
    setClientIP(clientIP);

    // Fetch allowed IP addresses
    axios.get("/clientchk", {
      headers: {
        'Cache-Control': 'no-cache',
      }
    })
      .then(response => {
        const fetchedAllowedIPs = response.data.allowedIPs;
        setAllowedIPs(fetchedAllowedIPs);

        // Compare client's IP with allowed IPs
        if (fetchedAllowedIPs.includes(clientIP)) {
          setMode('WELCOME');
        } else {
          setMode('LOGIN');
        }
      })
      .catch(error => {
          if (axios.isAxiosError(error)) {
            console.log('Error fetching allowed IPs:', error);
          }
        });
    })
  .catch(error =>  {
    if (axios.isAxiosError(error)) {
      console.log('Error fetching allowed IPs:', error);
    }
  });
 }, [setClientIP, setAllowedIPs, setMode, clientIP, axios]);

      //const onClickButton = useCallback(() => {
        //setOpen(!open);
      //}, [open]);

      const onClickButton = () => {
        // 다른 URL로 이동
        
        window.open("http://admin.ch-partners.kr", '_blank');
      };

      const onClickLogout = useCallback(() => {
        axios.defaults.withCredentials =true;
        axios.get("/logout")
          .then((response) => {
            const json = response.data;
            sessionStorage.clear();

            if (json.isLogin === "True") {
                setMode("WELCOME");
              } else {
                setMode("LOGIN");
              }
            });
      }, []);

      const onClickMozipinfo = useCallback(() => {
        setOpen_Mozip(!open_mozip);
      },[open_mozip]);

      const onClickCounselorList = useCallback(() => {
        setOpenCounselorList(!openCounselorList);
      }, [openCounselorList]);


      let content = null; 
    
  
      if(mode === "LOGIN")
      {
        content = <div className="d-flex ms-lg-4"><button className="btn btn-secondary-outline" href="#" onClick={onClickButton}>Login</button></div>
        //content = <div class="text-center text-md-start"><a class="btn btn-link text-warning fw-medium" href="#!" role="button" data-bs-toggle="modal" data-bs-target="#popupLoign"><span class="fas fa-play me-2"></span>Login </a></div>
        //content = <div className="text-center text-md-start"><button className="btn btn-link text-warning fw-medium" href="#" onClick={onClickButton}>Login</button></div>
      }
      else if(mode === "WELCOME") {
        content = <>
        <div className="d-flex ms-lg-4"><button className="btn btn-secondary-outline" href="#" onClick={onClickLogout}>Logout</button></div>
        </>
     }     
    
    useEffect(() => {
        const handleScroll = () => {
            setSticky(window.scrollY > 200);    
        };
        window.addEventListener("scroll", handleScroll);
        return() => window.removeEventListener("scroll", handleScroll);
    });

    const [redValue, setBlueValue] = useState(0);
    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY || window.pageYOffset;
            const maxScroll = document.documentElement.scrollHeight - window.innerHeight;
            
            // 여기에서 다양한 로직을 사용하여 원하는 대로 RGB 값 조절 가능
            const newBlueValue = Math.max((scrollY / maxScroll) * 255 * 0.01, 0);
            setBlueValue(newBlueValue);
        };
        window.addEventListener("scroll", handleScroll);
        return() => window.removeEventListener("scroll", handleScroll);
    }); 

    const buttonRef = useRef(null);
    if(buttonRef.current) {
        const newclassName = `bg-light`;
    }

    const backgroundColor = `rgb(249, 250, 253,${redValue})`;
    const backgroundImage = `none`;
    const transition = `background-color 0s ease 0s, padding 0.35s ease 0s`;

    return (                                                                                                                                                                //style={{backgroundImage:'url("assets/img/category/shape.png")',opacity:'0.5'}}                
        
        <nav className={`${classSticky ? "navbar navbar-expand-lg navbar-light sticky-top backdrop" : "navbar navbar-expand-lg navbar-light sticky-top "}`} data-navbar-on-scroll="data-navbar-on-scroll" style={{backgroundImage,backgroundColor,transition}}  >

            
            <div className="container">
                <ul className="navbar-lgbrand">
                <li className="text-lgbrand fw-bold" >한국투자저축은행 대출모집법인</li>
                <a className="navbar-brand" href="/">주식회사 씨에이치파트너스</a><br/>
                <li className="text-lgbrand " >은행연합회 대출모집인등록번호:20-00000571</li>

                </ul>
            <button className="navbar-toggler" ref={buttonRef} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"> </span></button>
            <div className="navbar-collapse border-top border-lg-0 mt-4 mt-lg-0" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto">
                
                <li className="nav-item"><Link class="nav-link" to='/product'>살만한대출</Link></li>

                
                </ul>
                {content}
                 
            </div>
            </div>
              
            <LoginPage open={open} onClickButton={onClickButton} props={{ setMode }} />
            <MozipInfoPage open={open_mozip} onClickMozipinfo={onClickMozipinfo} />
            <CounselorListPage open={openCounselorList} onClose={onClickCounselorList} />
        
        </nav> 
        
    );
}