import React from "react";

export default function Banner(){
    return (
        <section className="pt-5 pt-md-9 mb-6" id="feature">
        
        <div className="bg-holder z-index--1 bottom-0 d-none d-lg-block" style={{backgroundImage:'url("assets/img/category/shape.png")',opacity:'0.5'}}>
        </div>
        <div className="container">
          <h1 className="fs-6  mb-4 text-center"> 한도와 금리 모두 필요한 고객님을 위해! <br className="d-none d-xl-block" />살만한 대출</h1>
          <div className="row2 pt-md-7 text-center">
            <div className="col-sm-4 mb-2"> <img className="mb-2 ms-n2" src="assets/img/category/icon8.png" width="120" alt="Feature"  />
              <h4 className="mb-3">연 10.9% ~ 19.9%</h4>
             
            </div>
            <div className="col-sm-4 mb-2"> <img className="mb-2 ms-n2" src="assets/img/category/icon6.png" width="120" alt="Feature" />
              <h4 className="mb-3">비대면 대출상담</h4>
              
            </div>
            <div className="col-sm-4 mb-2"> <img className="mb-2 ms-n2" src="assets/img/category/icon7.png" width="120" alt="Feature" />
              <h4 className="mb-3">최대 1억원</h4>
              
            </div>
            {/*
            <div className="col-sm-2 mb-2"> <img className="mb-2 ms-n3" src="assets/img/category/icon4.png" width="75" alt="Feature" />
              <h4 className="mb-3">Five second tests</h4>
              <p className="mb-0 fw-medium text-secondary">Personal choices and the overall personality of the person.</p>
            </div>
            */}
          </div>
          
        </div>

      </section>
    );
}