import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import App from './App';
import Home from './pages/home';
import NewProduct from './pages/product';
import NotFound from './pages/NotFound';


const router = createBrowserRouter([
  {
    path: '/',
    element:<App />,
    errorElement:<NotFound />,
    children:[
      {index: true, path: '/', element: <Home />},
      {
        path:'/product', 
        element:<NewProduct />
      },
  
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('top'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);
