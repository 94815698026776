import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { styled, useTheme } from '@mui/material/styles'
import MuiCard from '@mui/material/Card'

// ** Configs
import themeConfig from '../configs/themeConfig'

// ** Layout Import
import BlankLayout from '../@core/layouts/BlankLayout'

// ** Demo Imports
import FooterIllustrationsV1 from '../views/pages/auth/FooterIllustration'


    // ** Styled Components
const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { width: '28rem' }
}))



const PAGE_SIZE = 5;

const CounselorListPage = ({ open, onClickCounselorList, props }) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [editItem, setEditItem] = useState(null);



  
  const fetchData = async () => {
    try {
      const response = await axios.get("/list"); // Adjust the API endpoint
      console.log(response.data);
      setData(response.data);
      setTotalPages(Math.ceil(response.data.length / PAGE_SIZE));
    } catch (error) {
      if (axios.isAxiosError(error)) {
      console.log('Error fetching data:', error);
      }
    }
  };

  // ** Hook
const theme = useTheme();



useEffect(() => {
  fetchData();
  }, []);


useEffect(() => {

  if(open) {
    document.body.style= `overflow: hidden`;
  }
    return () => { 
      document.body.style = `overflow: auto`
    };
  }, [open]);



  const handlePageChange = (newPage) => {
    setCurrentPage(Math.max(1, Math.min(newPage, totalPages)));
  };

  const handleEdit = (item) => {
    setEditItem(item);
    // Handle edit logic or open a modal here
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/list_delete/${id}`); // Adjust the API endpoint
      fetchData();
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  return (
    <div className={open ? "modal-wrapper" : null} >   
    {open ? (
    
      <Box className='content-center' >
       
      <Card sx={{ zIndex: 1 }}>
        <Box sx ={{marginLeft: 53, justifyContent: 'right', alignItems: 'right'}}>
            <button
            fullWidth
            size='large'
            variant='contained'
            
            label='X'
            onClick={() => {onClickCounselorList()}}
            >
            ⓧ
            </button>
        </Box> 
        <CardContent sx={{ padding: theme => `${theme.spacing(12, 9, 7)} !important` }}>  
          <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            
            <Typography
              variant='h6'
              sx={{
                ml: 3,
                lineHeight: 1,
                fontWeight: 600,
                textTransform: 'uppercase',
                fontSize: '1.5rem !important'
              }}
            >
              {themeConfig.templateName}
            </Typography>
          </Box>
      <div className="popup-overlay">
        <div className="popup-content">
          <h2>상담사 리스트</h2>
          <table>
            <thead>
              <tr>
                <th>상담사 코드</th>
                <th>이름</th>
                <th>날짜</th>
                <th>동작</th>
              </tr>
            </thead>
            <tbody>
              {data
                .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
                .map((item) => (
                  <tr key={item.id}>
                    <td>{item.counselorCode}</td>
                    <td>{item.name}</td>
                    <td>{item.date}</td>
                    <td>
                      <button onClick={() => handleEdit(item)}>수정</button>
                      <button onClick={() => handleDelete(item.id)}>삭제</button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              이전 페이지
            </button>
            {[...Array(totalPages).keys()].map((num) => (
              <button key={num + 1} onClick={() => handlePageChange(num + 1)}>
                {num + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              다음 페이지
            </button>
          </div>
          
        </div>
      </div>
      </CardContent>
      </Card>
      <FooterIllustrationsV1 />
    </Box>
  ) : null}
  </div>
  )}
CounselorListPage.getLayout = page => <BlankLayout>{page}</BlankLayout>
export default CounselorListPage;
