import React from "react";

export default function Mainbody(){
    return (
        <section className="pt-5" id="manager">

        <div className="container">
          <div className="row">
            <div className="col-lg-6"><img className="img-fluid" src="assets/img/manager/manager.png" alt="" /></div>
            <div className="col-lg-6">
              <h2 className="mb-3 fs-6 fw-bold ">상품상세내용</h2>
              <p className="mb-3 border-top"></p>
              <p className="mb-2 fs-3 fw-bold  text-secondary ">살만한 대출</p>
              <p className="mb-3 border-top"></p>
              <div className="d-flex align-items-center mb-3"><span className="me-2" >대  상  고  객  </span>
                <p className="fw-medium mb-0 text-secondary me-sm-6 mb-min">만 19세이상 직장인 및 직장인 성격의 프리랜서, 개인사업자, 주부<br />단, NICE신용평점 350점 이상인 자</p>
              </div>
              <p className="mb-3 border-top"></p>
              <div className="d-flex align-items-center mb-3"><span className="me-2" >대  출  한  도  </span>
                <p className="fw-medium mb-0 text-secondary me-sm-6 mb-min">최소 100만원~최대1억원</p>
              </div>
              <p className="mb-3 border-top"></p>
              <div className="d-flex align-items-center mb-3"><span className="me-2 col-red" >대  출  금  리  </span>
                <p className="fw-medium mb-0 text-secondary me-sm-6 mb-min"><ul className="col-red">최저 10.9%~19.9%(고정금리)</ul> </p>
              </div>
              <p className="mb-3 border-top"></p>
              <div className="d-flex align-items-center mb-3"><span className="me-2 " >대  출  기  간  </span>
                <p className="fw-medium mb-0 text-secondary me-sm-6 mb-min"> 12개월~120개월(대출금액별 대출기간 차등)</p>
              </div>
              <p className="mb-3 border-top"></p>
              <div className="d-flex align-items-center mb-3"><span className="me-2 " >이자부과시기</span>
                <p className="fw-medium mb-0 text-secondary me-sm-6 mb-min"> 매월 고객대출 약정일</p>
              </div>
              <p className="mb-3 border-top"></p>
              <div className="d-flex align-items-center mb-3"><span className="me-2  col-red" >연  체  금  리  </span>
                <p className="fw-medium mb-0 text-secondary me-sm-6 mb-min"> <ul className="col-red">연체시:약정이자+연3%(*연체이자율 상한:연20%이내)</ul></p>
              </div>
              <p className="mb-3 border-top"></p>
              <div className="d-flex align-items-center mb-3"><span className="me-2 " >상  환  방  식  </span>
                <p className="fw-medium mb-0 text-secondary me-sm-6 mb-min">원리금균등상환방법(최대 120개월)</p>
              </div>
              <p className="mb-3 border-top"></p>
              <div className="d-flex align-items-center mb-3"><span className="me-2 " >취급수수료  </span>
                <p className="fw-medium mb-0 text-secondary me-sm-6 mb-min"> 없음.</p>
              </div>
              <p className="mb-3 border-top"></p>
              <div className="d-flex align-items-center mb-3"><span className=" me-2 " >대출기한전<br/>상환수수료 </span>
                <p className="fw-medium mb-0 text-secondary me-sm-6 mb-min" > 대출취급 후 3년이내 상환금액의 2.0% 이후는 면제<br />(기한전상환대출금액x기한전상환수수료x대출잔여일수/대출기간)</p>
              </div>
              <p className="mb-3 border-top"></p>
              <div className="d-flex align-items-center mb-3"><span className="me-2 " >인지비용부담  </span>
                <p className="fw-medium mb-0 text-secondary me-sm-6 mb-min"> 대출금 5천만원까지는 수입인지 비용이 없으며,<br /> 5천만원 초과시 대출금액별 수입인지 비용차등 적용.<br /> 수입인지 비용은 각 50%씩 고객과 저축은행이 부담 </p>
              </div>
              
            </div>
          </div>
        </div>

      </section>
    );

}
