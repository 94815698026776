import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import { ButtonToolbar } from "react-bootstrap";
import Product_top from "../pages/product_top";
import Product_info from "../pages/product_info";
import Product_down from "../pages/product_down";
import Lastbuttom from "../components/Lastbuttom";
import Copylight_s from "../components/Copylight";
//import { useProductContext } from "../components/context/ProductContext";

function Product(props){
  const [options, setOptions] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [textValue, setGetValue] = useState("");
  const productTopRef = useRef(null); 

  const getTextValue = (text) => {
    setGetValue(text);
  }

  const handleButtonClick = () => {
    if (productTopRef.current && !textValue.mozipName) {
      productTopRef.current.focusSelect();
    }
  }

  useEffect(() => {

    axios.get("/product", {  
      params: {
        params_info: "pro"
      },
      
    })
    .then((response) => {
        var sessionDataArray  = response.data
        console.log("ddddddd " +sessionDataArray.value);
        console.log("ddddddd " +sessionDataArray.name);
        //const options = [options[0] || {}]
        setOptions(sessionDataArray);
      });

      axios.get("/product_sub", {  
        params: {
          params_info: "pro"
        },
        
      })
      .then((response) => {
          var responseData   = response.data
          if (responseData  && responseData[0]) {
            // 0번 인덱스에 해당하는 객체에서 필요한 속성 추출
            const dataAtIndex0 = responseData[0];
            const code = dataAtIndex0.code;
            const id = dataAtIndex0.id;
            const mobile = dataAtIndex0.mobile;
            const urls = dataAtIndex0.urls;
            const value = dataAtIndex0.value;

            // 원하는 데이터 처리 또는 상태 업데이트
            // 예: setOptions2를 사용하여 상태 업데이트
            setOptions2({
              code,
              id,
              mobile,
              urls,
              value
              
            });
          }
        });

  }, []);
 

    return (
        <>
        <Product_top ref={productTopRef} options = {options} options2 = {options2} getTextValue={(text) => setGetValue(text)}/>
        <Product_info mozipname={textValue.mozipName} mozipcode={textValue.mozipCode} handleButtonClick ={handleButtonClick}/>
        <Product_down/>
        <Lastbuttom />
        <Copylight_s />
        </>
    );
}

export default Product;