import React from "react";

export default function Mainbody(){
    return (
        <section className="pt-5" id="validation">

        <div className="container">
          <div className="row">
            <div className="col-lg-6">
             
              <h2 className="mb-2 fs-7 fw-bold">자주묻는질문</h2>
              <p className="mb-3 border-top"></p>
              <h4 className="mb-2 fs-1 fw-bold">Q : 내 개인 신용평점에는 영향이 없나요?</h4>
              <p className="mb-4 fs-1 fw-semi-bold text-secondary fs-sm-2"><span className="fs-1 fw-bold">A : </span> 대출한도조회는 신용평가에 반영되지 않기 때문에 대출의 한도와 금리를 확인하는 절차에서는 신용평점에 아무런 영향이 없습니다.</p>
              <p className="mb-3 border-top"></p>
              <h4 className="mb-2 fs-1 fw-bold">Q : 신청시 대출금 입금까지는 얼마나 걸리나요?</h4>
              <p className="mb-4 fw-fs-1 fw-semi-bold text-secondary fs-sm-2"><span className="fs-1 fw-bold">A : </span>원활한 상담이 이루어 질 시에 심사 및 송금은 당일 또는 익일내로 이루어 집니다.</p>
              <p className="mb-3 border-top"></p>
              <h4 className="mb-2 fs-1 fw-bold">Q : 대출을 받을때 수수료가 있나요?</h4>
              <p className="mb-4 fw-fs-1 fw-semi-bold text-secondary fs-sm-2"><span className="fs-1 fw-bold">A : </span>수수료를 요구하거나 받는것은 불법으로, 대출과 관련된 일체의 수수료는 받지 않습니다.</p>
            </div>
            <div className="col-lg-6"><img class="img-fluid" src="assets/img/validation/validation.png" alt="" /></div>
          </div>
        </div>

      </section>
    );

}
