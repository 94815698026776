import React from "react";
import { Link } from "react-router-dom";

export default function Banner(){
    return (
        <section className="pt-7">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 text-md-start text-center py-6">
              <h1 className="mb-4 fs-6 text-brand fw-bold" align="center">한도 금리가 궁금한다면<br/>비교할수록 보이는 올바른 금융</h1>
              <p className="mb-6 lead text-secondary text-brand" align="center">
              <h2 className="mb-3 fs-2">최대1억원 / 연 10.9% ~ 19.9%(고정금리) / 최대 120개월이내 </h2>
              <br className="d-none d-xl-block" />이제 방문 하지 마세요! 비대면 상담으로
              <br className="d-none d-xl-block" />고객님의 소중한 시간을 아껴드립니다.</p>
              <div className="text-center" ><Link className="btn btn-warning me-3 btn-lg" to='/product' role="button" >대출신청하기</Link></div>
              
            </div>
            <div className="col-md-6 text-end"><img className="pt-7 pt-md-0 img-fluid" src="assets/img/hero/hero-img.png" alt="" /></div>
          </div>
        </div>
      </section>
    );
}