import React from "react";

export default function Copylight(){
    return (
        <section class="text-center py-0">

            <div class="container">
            <div class="container border-top py-3">
                <div class="row justify-content-between">
                <div class="col-12 align-items-center mb-1 mb-md-0">
                    <p class="mb-0">&copy; 2023 주식회사 씨에이치파트너스 All Rights Reserved </p>
                </div>
                </div>
            </div>
            </div>

        </section>
    );
}