import React from "react";
import { ButtonToolbar } from "react-bootstrap";
import Banner from "../components/Banner";
import Banner2 from "../components/Banner2";
import Mainbody from "../components/Mainbody";
import Buttom from "../components/Buttom";
import Mainbody_sub from "../components/Mainbody_sub";
import Lastbuttom from "../components/Lastbuttom";
import Copylight_s from "../components/Copylight";

export default function Home(){
    return (
        <>
        <Banner />
        <Banner2/>
        <Mainbody_sub />
        <Mainbody />
        <Buttom />
        <Lastbuttom />
        <Copylight_s />
        </>
    );
}