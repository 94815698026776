import { useContext } from "react";
import styled, { css } from "styled-components";
import RadioContext from "./RadioContext";

const StyledLabel = styled.label`
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-bottom: 8px; /* Example: Adjust margin bottom */
  margin-right : 5px;
`;

const StyledInput = styled.input`
  /* Hide the default radio button */
  display: none;
`;

const CustomCheckbox = styled.label`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-right : 5px;
  left: 0;
  top: 0;

  ${({ isChecked }) =>
    isChecked
      ? css`
          background-color: #66bb6a;
          border-color: #66bb6a;
          &:after {
            border: 2px solid #fff;
            border-top: none;
            border-right: none;
            content: "";
            height: 4px;
            left: 4.5px;
            position: absolute;
            top: 6px;
            transform: rotate(-45deg);
            width: 8px;
          }
        `
      : css`
          background-color: #fff !important;
          &:after {
            opacity: 1;
          }
        `}

        &:not([disabled]) {
          cursor: pointer;
          &:hover {
            // You can add hover styles if needed
          }
          &:active {
            transform: scale(0.95);
          }
          // Add click event handling
          &:active::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
`;

  
const Radio = ({ children, value, name, defaultChecked, disabled}) => {
    const group = useContext(RadioContext);
  
    return (
      <StyledLabel>
        <StyledInput
          type="radio"
          value={value}
          name={name}
          defaultChecked={defaultChecked}
          disabled={disabled || group.disabled}
          checked={group.value !== undefined ? value === group.value : undefined}
          onChange={(e) => group.onChange && group.onChange(e.target.value)}
        />
        <CustomCheckbox isChecked={group.value === value} 
        onClick={() => group.onChange && group.onChange(value)}
        />
        {children}
      </StyledLabel>
    );
};

export default Radio;